<i18n>
{
  "de": {
    "returnButton": "Zurück",
    "pageTitle": "Portfolioeinstellungen"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <button-wrapper>
        <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}">{{ $t('returnButton') }}</router-link>
      </button-wrapper>
      <br /><br />
      <edit-portfolio-params :portfolio="portfolio" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditPortfolioParams from '@/components/admin-portfolios/EditPortfolioParams'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    MainLayout,
    EditPortfolioParams,
    ButtonWrapper
  }
}
</script>
