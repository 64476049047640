<i18n>
{
  "de": {
    "startYearLabel": "Startjahr",
    "endYearLabel": "Endjahr",
    "activeFeaturesTitle": "Aktivierte Features",
    "featurePvProfitability": "Wirtschaftlichkeit Photovoltaik",
    "featureKpi": "Kennzahlen",
    "featureHeatingFeasibility": "Machbarkeit Heizungsersatz",
    "featureHeatingMeasures": "Massnahmen Heizung",
    "featureEnvelopeMeasures": "Massnahmen Gebäudehülle",
    "featurePvMeasures": "Massnahmen Photovoltaik",
    "featureZonesMeasures": "Massnahmen Zonen und Flächen",
    "enabled": "Aktiviert",
    "disabled": "Deaktiviert",
    "interestRate": "Zinssatz",
    "amortizationTitle": "Wirtschaftliche Lebensdauer (Amortisierung)",
    "pvAmortization": "Photovoltaik",
    "years": "Jahre",
    "operationCostsTitle": "Betriebskosten",
    "pvOperationCosts": "Photovoltaik",
    "energyPricesTitle": "Energiepreise",
    "elPriceGrid": "Strom",
    "elPriceFeedin": "Strom Rückspeisung",
    "elPriceInflation": "Strompreisteuerung",
    "eivTitle": "Einmalvergütung Photovoltaik",
    "eivMinCapacity": "Minimale Leistung",
    "eivLargeThreshold": "Leistungsgrenze Klein/Gross",
    "eivIntegratedBase": "Basis (Integriert)",
    "eivIntegratedPerKwpSmall": "Klein (Integriert)",
    "eivIntegratedPerKwpLarge": "Gross (Integriert)",
    "eivFreestandingBase": "Basis (Aufdach)",
    "eivFreestandingPerKwpSmall": "Klein (Aufdach)",
    "eivFreestandingPerKwpLarge": "Gross (Aufdach)"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-portfolio-params">
    <form-row :label="$t('startYearLabel')">
      <numeric-input noFormatting :min="1900" :max="2100" v-model="model.sim_start_year" :edit="isEditing" @validation="onValidation($event, 'start_year')" />
    </form-row>
    <form-row :label="$t('endYearLabel')">
      <numeric-input noFormatting :min="1900" :max="2100" v-model="model.sim_end_year" :edit="isEditing" @validation="onValidation($event, 'end_year')" />
    </form-row>
    <form-row :label="$t('interestRate')">
      <numeric-input v-model="model.interest_rate" :edit="isEditing" :units="'%'" />
    </form-row>
    <h3>{{ $t('activeFeaturesTitle') }}</h3>
    <form-row :label="$t('featurePvProfitability')">
      <check-input v-model="model.enabled_features.PV_PROFITABILITY" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featureKpi')">
      <check-input v-model="model.enabled_features.KPI" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featureHeatingFeasibility')">
      <check-input v-model="model.enabled_features.HEATING_FEASIBILITY" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featureHeatingMeasures')">
      <check-input v-model="model.enabled_features.HEATING_MEASURES" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featureEnvelopeMeasures')">
      <check-input v-model="model.enabled_features.ENVELOPE_MEASURES" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featurePvMeasures')">
      <check-input v-model="model.enabled_features.PV_MEASURES" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <form-row :label="$t('featureZonesMeasures')">
      <check-input v-model="model.enabled_features.ZONES_MEASURES" :edit="isEditing" :label="$t('enabled')" :active-label="$t('enabled')" :inactive-label="$t('disabled')" />
    </form-row>
    <h3>{{ $t('amortizationTitle') }}</h3>
    <form-row :label="$t('pvAmortization')">
      <numeric-input noFormatting :min="1" :max="1000" v-model="model.amortization_durations.pv" :units="$t('years')" :edit="isEditing" />
    </form-row>
    <h3>{{ $t('operationCostsTitle') }}</h3>
    <form-row :label="$t('pvOperationCosts')">
      <numeric-input v-model="model.operation_costs.pv" :units="'CHF/kWh'" :edit="isEditing" />
    </form-row>
    <h3>{{ $t('energyPricesTitle') }}</h3>
    <form-row :label="$t('elPriceGrid')">
      <numeric-input v-model="model.energy_prices.electricity.grid" :units="'CHF/kWh'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('elPriceFeedin')">
      <numeric-input v-model="model.energy_prices.electricity.feedin" :units="'CHF/kWh'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('elPriceInflation')">
      <numeric-input v-model="model.energy_prices.electricity.inflation" :units="'%'" :edit="isEditing" />
    </form-row>
    <h3>{{ $t('eivTitle') }}</h3>
    <form-row :label="$t('eivMinCapacity')">
      <numeric-input v-model="model.subsidies.pv_eiv.min_capacity" :units="'kWp'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivLargeThreshold')">
      <numeric-input v-model="model.subsidies.pv_eiv.large_capacity_threshold" :units="'kWp'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivIntegratedBase')">
      <numeric-input v-model="model.subsidies.pv_eiv.integrated_base" :units="'CHF'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivIntegratedPerKwpSmall')">
      <numeric-input v-model="model.subsidies.pv_eiv.integrated_per_kwp_small" :units="'CHF/kWp'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivIntegratedPerKwpLarge')">
      <numeric-input v-model="model.subsidies.pv_eiv.integrated_per_kwp_large" :units="'CHF/kWp'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivFreestandingBase')">
      <numeric-input v-model="model.subsidies.pv_eiv.freestanding_base" :units="'CHF'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivFreestandingPerKwpSmall')">
      <numeric-input v-model="model.subsidies.pv_eiv.freestanding_per_kwp_small" :units="'CHF/kWp'" :edit="isEditing" />
    </form-row>
    <form-row :label="$t('eivFreestandingPerKwpLarge')">
      <numeric-input v-model="model.subsidies.pv_eiv.freestanding_per_kwp_large" :units="'CHF/kWp'" :edit="isEditing" />
    </form-row>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import NumericInput from '@/components/shared/forms/NumericInput'
import FormRow from '@/components/shared/forms/FormRow'
import CheckInput from '@/components/shared/forms/CheckInput'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    NumericInput,
    CheckInput,
    EditContainer,
    FormRow
  },
  watch: {
    portfolio () {
      this.resetModel()
    }
  },
  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams'
    }),
    resetModel () {
      this.model = JSON.parse(JSON.stringify({
        sim_start_year: this.portfolio.sim_start_year,
        sim_end_year: this.portfolio.sim_end_year,
        enabled_features: this.portfolio.enabled_features,
        amortization_durations: this.portfolio.amortization_durations,
        interest_rate: this.portfolio.interest_rate,
        operation_costs: this.portfolio.operation_costs,
        energy_prices: this.portfolio.energy_prices,
        subsidies: this.portfolio.subsidies
      }))
    },
    async saveHandler () {
      await this.updateParams({
        id: this.portfolio.id,
        params: this.model
      })
    }
  }
}
</script>
<style>
.edit-portfolio-params {
  max-width: 600px;
}
</style>
