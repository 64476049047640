<!--
ApiFormMixin
Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
Provides: model, isValid, isEditing, formState, onFormEvent, onValidation
-->
<script>
export default {
  data () {
    return {
      private__invalidFields: [], // List of fields that failed validation
      private__error: null, // Error message
      private__pending: false, // API request is currently pending
      private__edit: false, // Editing mode
      model: null // Temporary data model
    }
  },
  computed: {
    noInvalidFields () {
      return this.private__invalidFields.length === 0
    },
    // this method can be overwritten to implement custom validation logic
    isValid () {
      return this.noInvalidFields
    },
    isEditing () {
      return this.private__edit
    },
    formState () {
      return {
        pending: this.private__pending,
        edit: this.private__edit,
        error: this.private__error,
        isValid: this.isValid
      }
    }
  },
  created: function () {
    this.resetModel()
  },
  methods: {
    // Handler for edit fields that have validation event
    onValidation (isValid, fieldName) {
      if (isValid) {
        this.private__invalidFields = this.private__invalidFields.filter((r) => r !== fieldName)
      } else {
        if (this.private__invalidFields.indexOf(fieldName) === -1) {
          this.private__invalidFields.push(fieldName)
        }
      }
    },
    onFormEvent (eventType) {
      if (eventType === 'save') {
        this.private__onSave()
      } else if (eventType === 'cancel') {
        this.private__edit = false
        this.resetModel()
        this.$emit('cancel')
      } else if (eventType === 'edit') {
        this.private__edit = true
      }
    },
    // Handler for save button as a wrapper for saveHandler (which has to be implemented by Mixin container)
    async private__onSave () {
      this.private__pending = true
      try {
        await this.saveHandler(this.model)
      } catch (error) {
        this.private__error = error
      }
      this.private__pending = false
      this.$emit('save')
      this.private__edit = false
    }
  }
}
</script>
