<i18n>
{
  "de": {
    "pendingMessage": "Daten werden gespeichert, bitte warten...",
    "editButtonLabel": "Bearbeiten",
    "cancelButtonLabel": "Abbrechen",
    "saveButtonLabel": "Speichern"
  }
}
</i18n>
<template>
  <div>
    <div v-if="bindFormState.error">{{ bindFormState.error }}</div>
    <div v-if="bindFormState.pending">{{ $t('pendingMessage') }}</div>
    <div v-if="!bindFormState.pending">
      <slot />
      <button-wrapper>
        <button class="button" v-if="bindFormState.edit" :disabled="!bindFormState.isValid" @click="$emit('container', 'save')">{{ $t('saveButtonLabel') }}</button>
        <button class="button" v-if="bindFormState.edit" @click="$emit('container', 'cancel')">{{ $t('cancelButtonLabel')}}</button>
        <button class="button" v-else @click="$emit('container', 'edit')">{{ $t('editButtonLabel') }}</button>
      </button-wrapper>
    </div>
  </div>
</template>
<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'

export default {
  components: {
    ButtonWrapper
  },
  props: {
    bindFormState: {
      type: Object
    }
  }
}
</script>
